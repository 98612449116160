/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnInit {
  form = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  })
  i18n = {
    atencao: '',
    erroFormulario: ''
  }

  constructor(
    private usuarioService: UsuarioService,
    private toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  private setLanguage(): void {
    const currentLang = this.translateService.currentLang;
    if (!currentLang) {
      this.translateService.use(this.translateService.getBrowserLang() ?? 'pt'); 
    }
  }

  ngOnInit(): void {
    this.setLanguage();
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  login() {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    this.usuarioService.executeLogin(this.form.get('username')?.value ?? '', this.form.get('password')?.value ?? '')
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'shared.erro_formulario'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.erroFormulario = traducoes['shared.erro_formulario']
    })
  }
}
