/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { NgModule } from '@angular/core'
import { Route, RouterModule, Routes } from '@angular/router'

import { ListaInscricaoAbertaComponent } from './modules/concurso/lista-inscricao-aberta/lista-inscricao-aberta.component'
import { ListaInscricaoAndamentoComponent } from './modules/concurso/lista-inscricao-andamento/lista-inscricao-andamento.component'
import { ListaInscricaoEncerradaComponent } from './modules/concurso/lista-inscricao-encerrada/lista-inscricao-encerrada.component'
import { ListaInscricaoProximaComponent } from './modules/concurso/lista-inscricao-proxima/lista-inscricao-proxima.component'
import { ConcursoComponent } from './modules/concurso/concurso/concurso.component'
import { FichaInscricaoComponent } from './modules/concurso/ficha-inscricao/ficha-inscricao.component'
import { AuthComponent } from './modules/sistema/auth/auth.component'
import { SenhaRecuperarComponent } from './modules/sistema/senha-recuperar/senha-recuperar.component'
import { SenhaRedefinirComponent } from './modules/sistema/senha-redefinir/senha-redefinir.component'
import { CandidatoInscricoesComponent } from './modules/candidato/candidato-inscricoes/candidato-inscricoes.component'
import { CandidatoInscricaoFichaComponent } from './modules/candidato/candidato-inscricao-ficha/candidato-inscricao-ficha.component'
import { CandidatoInscricaoComplementoFase1Component } from './modules/candidato/candidato-inscricao-complemento-fase1/candidato-inscricao-complemento-fase1.component'
import { CandidatoInscricaoComplementoFase2Component } from './modules/candidato/candidato-inscricao-complemento-fase2/candidato-inscricao-complemento-fase2.component'
import { SenhaAlterarComponent } from './modules/candidato/senha-alterar/senha-alterar.component'
import { PageNotFoundComponent } from './modules/sistema/page-not-found/page-not-found.component'
import { RecursoCadastrarComponent } from './modules/candidato/recurso-cadastrar/recurso-cadastrar.component'
import { RecursoListarComponent } from './modules/candidato/recurso-listar/recurso-listar.component'
import { RecursoVisualizarComponent } from './modules/candidato/recurso-visualizar/recurso-visualizar.component'
import { ContatoComponent } from './modules/candidato/contato/contato.component'
import { ManualComponent } from './modules/sistema/manual/manual.component'
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    component: ListaInscricaoAbertaComponent,
  },
  {
    path: 'concurso/inscricao-aberta',
    pathMatch: 'full',
    component: ListaInscricaoAbertaComponent,
  },
  {
    path: 'concurso/inscricao-andamento',
    pathMatch: 'full',
    component: ListaInscricaoAndamentoComponent,
  },
  {
    path: 'concurso/inscricao-proxima',
    pathMatch: 'full',
    component: ListaInscricaoProximaComponent,
  },
  {
    path: 'concurso/inscricao-encerrada',
    pathMatch: 'full',
    component: ListaInscricaoEncerradaComponent,
  },
  {
    path: 'concurso/:id',
    pathMatch: 'full',
    component: ConcursoComponent,
  },
  {
    path: 'concurso/:id/inscricao',
    pathMatch: 'full',
    component: FichaInscricaoComponent,
  },
  {
    path: 'sistema/manual',
    pathMatch: 'full',
    component: ManualComponent,
  },
  {
    path: 'sistema/entrar',
    pathMatch: 'full',
    component: AuthComponent,
  },
  {
    path: 'sistema/senha-recuperar',
    pathMatch: 'full',
    component: SenhaRecuperarComponent,
  },
  {
    path: 'sistema/senha-redefinir/:codigo',
    pathMatch: 'full',
    component: SenhaRedefinirComponent,
  },
  {
    path: 'candidato/inscricoes',
    pathMatch: 'full',
    component: CandidatoInscricoesComponent,
  },
  {
    path: 'candidato/inscricao/:id',
    pathMatch: 'full',
    component: CandidatoInscricaoFichaComponent,
  },
  {
    path: 'candidato/inscricao/:id/complemento-fase-1',
    pathMatch: 'full',
    component: CandidatoInscricaoComplementoFase1Component,
  },
  {
    path: 'candidato/inscricao/:id/complemento-fase-2',
    pathMatch: 'full',
    component: CandidatoInscricaoComplementoFase2Component,
  },
  {
    path: 'candidato/inscricao/:id/recurso',
    pathMatch: 'full',
    component: RecursoCadastrarComponent,
  },
  {
    path: 'candidato/recursos',
    pathMatch: 'full',
    component: RecursoListarComponent,
  },
  {
    path: 'candidato/recurso/:id',
    pathMatch: 'full',
    component: RecursoVisualizarComponent,
  },
  {
    path: 'candidato/senha-alterar',
    pathMatch: 'full',
    component: SenhaAlterarComponent,
  },
  {
    path: 'candidato/senha-alterar',
    pathMatch: 'full',
    component: SenhaAlterarComponent,
  },
  ...(environment.enableTelaContato === 'true'
    ? ([{
        path: 'candidato/contato/:idConcurso',
        pathMatch: 'full',
        component: ContatoComponent,
      }] as Route[])
    : []),
  {
    path: '**',
    component: PageNotFoundComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
