/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, identity, Observable, throwError } from 'rxjs'
import { ErrorHandlerService } from './error-handler.service'
import { environment } from '../../environments/environment'
import { InscricaoComplementoSituacao } from '../models/inscricao-complemento-situacao'
import { InscricaoComplemento } from '../models/inscricao-complemento'

@Injectable({
  providedIn: 'root',
})
export class InscricaoComplementoService {
  private baseUrl: string

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {
    this.baseUrl = environment.baseUrlApi
  }

  pendencia(id: number): Observable<InscricaoComplementoSituacao> {
    return this.http
      .get<InscricaoComplementoSituacao>(`${this.baseUrl}/privado/candidato/inscricao/${id}/complemento/pendencia`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  upload(idInscricao: number, id: number, formData: FormData): Observable<InscricaoComplemento> {
    return this.http
      .post<InscricaoComplemento>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/${id}/upload`,
        formData
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  checkbox(idInscricao: number, id: number, formData: FormData): Observable<InscricaoComplemento> {
    return this.http
      .post<InscricaoComplemento>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/${id}/checkbox`,
        formData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  notificaCandidatoSobreAnexos(idInscricao: number, jsonData: any): Observable<InscricaoComplementoSituacao> {
    return this.http
      .post<InscricaoComplementoSituacao>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/notifica-candidato-anexos/`,
        jsonData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }

  verificaPeriodoEnvio(idInscricao: number, jsonData: any): Observable<InscricaoComplementoSituacao> {
    return this.http
      .post<InscricaoComplementoSituacao>(
        `${this.baseUrl}/privado/candidato/inscricao/${idInscricao}/complemento/verifica-periodo-envio/`,
        jsonData
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
