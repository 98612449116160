/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ConcursoService } from 'src/app/services/concurso.service'
import { ToastService } from '../../toast/services/toast.service'
import { ConcursoAndamento } from 'src/app/models/concurso-andamento'
import { InscricaoFicha } from 'src/app/models/inscricao-ficha'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { UsuarioService } from 'src/app/services/usuario.service'
import { Sexo } from 'src/app/enums/sexo'
import { EstadoCivil } from 'src/app/enums/estado-civil'
import { Estado } from 'src/app/enums/estado'
import { OrgaoExpedicaoRg } from 'src/app/enums/orgao-expedicao-rg'
import { GrauEscolaridade } from 'src/app/enums/grau-escolaridade'
import { Estrangeiro } from 'src/app/enums/estrangeiro'
import { CidadeService } from 'src/app/services/cidade.service'
import { Deficiencia } from 'src/app/enums/deficiencia'
import { DataService } from 'src/app/services/data.service'
import { ValidacaoService } from 'src/app/services/validacao.service'
import { Autodeclaracao } from 'src/app/enums/autodeclaracao'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { TranslateService } from '@ngx-translate/core'
import { firstValueFrom } from 'rxjs'

@Component({
  selector: 'app-ficha-inscricao',
  templateUrl: './ficha-inscricao.component.html',
  styleUrls: ['./ficha-inscricao.component.css'],
})
export class FichaInscricaoComponent implements OnInit {
  sexo = Sexo
  estadoCivil = EstadoCivil
  estado = Estado
  orgaoExpedicaoRg = OrgaoExpedicaoRg
  grauEscolaridade = GrauEscolaridade
  estrangeiro = Estrangeiro
  deficiencia = Deficiencia
  autodeclaracao = Autodeclaracao
  cidades: string[] = []
  temas: string[] = []

  id?: number
  concursoAndamento?: ConcursoAndamento
  conferencia: boolean | null = false
  loading: boolean = false
  mostrarRecursosEspeciaisProva: boolean = false

  comprovanteEstudante?: File
  comprovanteRenda?: File
  deficienciaLaudoMedico?: File
  requerimentoNomeSocial?: File
  atendimentoEspecialLaudoMedico?: File
  necessidadesLaudoMedico?: File
  documentoIdentificacao?: File
  inputClassComprovanteEstudante: string = 'form-control'
  inputClassComprovanteRenda: string = 'form-control'
  inputClassDeficienciaLaudoMedico: string = 'form-control'
  inputClassAtendimentoEspecialLaudoMedico: string = 'form-control'
  inputClassDocumentoIdentificacao: string = 'form-control'
  inputClassRequerimentoNomeSocial: string = 'form-control'

  nomeSocial: boolean = false
  nomeMae: boolean = true
  nomePai: boolean = true
  declaracaoVeracidade: boolean = false
  pontuacaoDiferenciada: boolean = false
  tipoConcurso?: TipoConcurso
  enderecoNacional: boolean = false

  senhaPattern: string = '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).{8,}$'

  form = new FormGroup({
    idConcurso: new FormControl(),
    nome: new FormControl('', Validators.required),
    dataNascimento: new FormControl('', Validators.required),
    nomeSocial: new FormControl({ value: '', disabled: true }),
    nomeMae: new FormControl('', Validators.required),
    nomePai: new FormControl('', Validators.required),
    profissao: new FormControl('', Validators.required),
    escolaridade: new FormControl('', Validators.required),
    sexo: new FormControl('', Validators.required),
    estadoCivil: new FormControl('', Validators.required),
    estrangeiro: new FormControl('', Validators.required),
    cpf: new FormControl({ value: '', disabled: true }),
    passaporte: new FormControl({ value: '', disabled: true }),
    rne: new FormControl({ value: '', disabled: true }),
    rg: new FormControl({ value: '', disabled: true }),
    uf: new FormControl({ value: '', disabled: true }),
    dataExpedicao: new FormControl({ value: '', disabled: true }),
    orgaoExpedicao: new FormControl({ value: '', disabled: true }),
    pais: new FormControl({ value: '', disabled: true }, Validators.required),
    estado: new FormControl({ value: '', disabled: true }, Validators.required),
    cidade: new FormControl({ value: '', disabled: true }, Validators.required),
    celular: new FormControl('', Validators.required),
    telefone: new FormControl(''),
    email: new FormControl('', Validators.required),
    enderecoNacional: new FormControl('', Validators.required),
    enderecoCep: new FormControl('', Validators.required),
    enderecoLogradouro: new FormControl('', Validators.required),
    enderecoNumero: new FormControl('', Validators.required),
    enderecoBairro: new FormControl('', Validators.required),
    enderecoCidade: new FormControl('', Validators.required),
    enderecoEstado: new FormControl('', Validators.required),
    enderecoComplemento: new FormControl(''),
    autodeclaraRaca: new FormControl(false, Validators.required),
    pontuacaoDiferenciada: new FormControl(false, Validators.required),
    autodeclaracao: new FormControl('NAO_INFORMADO', Validators.required),
    declaracaoVeracidade: new FormControl(false, Validators.requiredTrue),
    possuiDeficiencia: new FormControl('', Validators.required),
    deficiencia: new FormControl(),
    deficienciaLaudoMedico: new FormControl(),
    deficienciaNecessidadesProva: new FormControl(),
    concorrerVagaDeficiente: new FormControl('', Validators.required),
    canhoto: new FormControl('', Validators.required),
    lactante: new FormControl('', Validators.required),    
    possuiOutraNecessidade: new FormControl('', Validators.required),
    atendimentoEspecialLaudoMedico: new FormControl(),
    atendimentoEspecialNecessidadesProva: new FormControl(),
    temas: new FormControl(''),
    reducao: new FormControl(''),
    reducaoTermo: new FormControl(''),
    reducaoComprovanteEstudante: new FormControl(''),
    documentoIdentificacao: new FormControl('', Validators.required),
    requerimentoNomeSocial: new FormControl(),
    reducaoComprovanteRenda: new FormControl(''),
    senha: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
    senhaConfirmacao: new FormControl('', [Validators.required, Validators.pattern(this.senhaPattern)]),
    jurado: new FormControl('', Validators.required),
    provaIngles: new FormControl('')
  })

  i18n = {
    atencao: '',
    parabens: '',
    concursoForaPeriodo: '',
    erroFormulario: '',
    senhasNaoBatem: '',
    cpfInvalido: '',
    confiraDados: '',
    selecaoTemas: '',
    erroReducaoTaxas: '',
    erroLaudoDeficiencia: '',
    erroLaudoAtendimentoEspecial: '',
    erroIdentificacao: '',
    sucessoInscricao: '',
    arquivoPdf: '',
    arquivoExcedeu100mb: '',
    arquivoTipo: '',
    arquivoExcedeu10mb: '',
    erroRequerimentoNomeSocial: ''
  }

  constructor(
    private readonly concursoService: ConcursoService,
    private readonly inscricaoService: InscricaoService,
    private readonly usuarioService: UsuarioService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly toastService: ToastService,
    private readonly cidadeService: CidadeService,
    private readonly dataService: DataService,
    private readonly validacaoService: ValidacaoService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    this.route.params.subscribe(params => {
      this.id = params['id']
      if (this.id) {
        this.concursoService.get(this.id).subscribe(concursoAndamento => {
          this.concursoAndamento = concursoAndamento
          this.tipoConcurso = concursoAndamento.tipoConcurso
          if (!this.concursoAndamento.periodoInscricao) {
            this.toastService.showErrorToast(this.i18n.atencao, this.i18n.concursoForaPeriodo)
            this.router.navigate(['/concurso/inscricao-aberta'])
          }
          this.temas = this.concursoAndamento.temas
          this.validacaoService.validaTemas(this.form, this.concursoAndamento.temas.length > 0)
          this.form.get('idConcurso')?.setValue(this.concursoAndamento?.id.toString())
          this.aplicaValidacoes()
        })
      }
    })
  }

  aplicaValidacoes(): void {
    this.formDataFromLocalStorage()
    this.validaEstrangeiro()
    this.selecionaEstado()
    this.validaAutodeclaraRaca()
    this.validaDeficiencia()
    this.validaAtendimentoEspecial()
    this.validaCEP(false)
    this.validaReducao()
    this.validaNomeSocial()
    this.validaMaeDesconhecida()
    this.validaPaiDesconhecido()
    this.habilitarOcultarProvaIngles()
  }

  habilitarOcultarProvaIngles() {
    if(this.concursoAndamento?.permiteProvaIngles) {
      this.form.get('provaIngles')?.enable()
      this.form.get('provaIngles')?.setValidators(Validators.required)
      this.form.get('provaIngles')?.updateValueAndValidity()
    }
  }

  applyTouchedToInvalidFields() {
    for (const name in this.form.controls) {
      if (this.form.get(name)?.invalid) {
        this.form.get(name)?.markAsTouched()
      }
    }
  }

  voltar(): void {
    this.location.back()
  }

  cancelar(): void {
    this.conferencia = false
    this.form.enable()
    this.aplicaValidacoes()
  }

  conferir(): void {
    this.applyTouchedToInvalidFields()

    if (!this.form.valid) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroFormulario)
      return
    }

    if (this.form.get('senha')?.getRawValue() != this.form.get('senhaConfirmacao')?.getRawValue()) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.senhasNaoBatem)
      return
    }

    if (!this.validacaoService.isCPFValid(this.form.get('cpf')?.getRawValue())) {
      this.toastService.showErrorToast(this.i18n.atencao, this.i18n.cpfInvalido)
      return
    }

    this.conferencia = null
    setTimeout(() => {
      this.conferencia = true
    }, 300)
    this.form.disable()
    this.toastService.showWarningToast(this.i18n.atencao, this.i18n.confiraDados)
  }

  async gravar(): Promise<void> {
    const formularioTemporario = this.form.value
    const { enderecoNacional, ...formularioFinal } = formularioTemporario
    let formulario = <InscricaoFicha>formularioFinal
    formulario.dataNascimento = this.dataService.formatToUs(formulario.dataNascimento)
    formulario.dataExpedicao = this.dataService.formatToUs(formulario.dataExpedicao)
    // TEMAS
    if (this.temas.length) {
      let qtdeTemasSelecionado = formulario.temas.length ?? 0
      if (qtdeTemasSelecionado != 2) {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.selecaoTemas)
        return
      }
    }
    if (!formulario.reducao) {
      formulario.reducao = 'false'
    }
    if (!this.concursoAndamento?.permiteProvaIngles) {
      formulario.provaIngles = 'false'
    }
    if (formulario.possuiDeficiencia === 'false') {
      formulario.concorrerVagaDeficiente = 'false'
    }
    this.loading = true
    // Envia os dados do formulário
    let inscricaoResponse = await firstValueFrom(this.inscricaoService.gravar(formulario))
    if (inscricaoResponse.message === 'Success' && inscricaoResponse.idInscricao) {
      // Quando houver anexos referente ao pedido de redução de taxa de inscrição
      const formData = new FormData()
      if (this.form.controls['reducaoComprovanteEstudante'].getRawValue() &&
          this.form.controls['reducaoComprovanteRenda'].getRawValue()) {
        formData.append('idCandidato', inscricaoResponse.idCandidato.toString())
        formData.append('comprovanteEstudante', this.comprovanteEstudante!)
        formData.append('comprovanteRenda', this.comprovanteRenda!)
        const reducaoResponse = await firstValueFrom(this.inscricaoService.reducaoTaxa(inscricaoResponse.idInscricao, formData))
        if (reducaoResponse.message !== 'Success') {
          this.toastService.showErrorToast(
            this.i18n.atencao,
            this.i18n.erroReducaoTaxas
          )
        }
      }
      if (this.form.controls['requerimentoNomeSocial'].getRawValue()) {
        formData.append('idCandidato', inscricaoResponse.idCandidato.toString())
        formData.append('requerimentoNomeSocial', this.requerimentoNomeSocial!)
        const requerimentoResponse = await firstValueFrom(this.inscricaoService.requerimentoNomeSocial(inscricaoResponse.idInscricao, formData))
        if (requerimentoResponse.message !== 'Success') {
          this.toastService.showErrorToast(
            this.i18n.atencao, 
            this.i18n.erroLaudoDeficiencia
          )
        }
      }  
      if (this.form.controls['deficienciaLaudoMedico'].getRawValue()) {
        formData.append('idCandidato', inscricaoResponse.idCandidato.toString())
        formData.append('deficienciaLaudoMedico', this.deficienciaLaudoMedico!)
        const laudoResponse = await firstValueFrom(this.inscricaoService.deficienciaLaudoMedico(inscricaoResponse.idInscricao, formData))
        if (laudoResponse.message !== 'Success') {
          this.toastService.showErrorToast(
            this.i18n.atencao, 
            this.i18n.erroLaudoDeficiencia
          )
        }
      }
      if (this.form.controls['atendimentoEspecialLaudoMedico'].getRawValue()) {
        formData.append('idCandidato', inscricaoResponse.idCandidato.toString())
        formData.append('atendimentoEspecialLaudoMedico', this.atendimentoEspecialLaudoMedico!)
        const laudoResponse = await firstValueFrom(this.inscricaoService.atendimentoEspecialLaudoMedico(inscricaoResponse.idInscricao, formData))
        if (laudoResponse.message !== 'Success') {
          this.toastService.showErrorToast(
            this.i18n.atencao, 
            this.i18n.erroLaudoAtendimentoEspecial
          )
        }
      }
      if (this.form.controls['documentoIdentificacao'].getRawValue()) {
        formData.append('idCandidato', inscricaoResponse.idCandidato.toString())
        formData.append('documentoIdentificacao', this.documentoIdentificacao!)
        const docIdentificacaoResponse = await firstValueFrom(this.inscricaoService.documentoIdentificacao(inscricaoResponse.idInscricao, formData))
        if (docIdentificacaoResponse.message !== 'Success') {
          this.toastService.showErrorToast(
            this.i18n.atencao, 
            this.i18n.erroIdentificacao
          )
        }
      }
      // Notifica o sucesso da inscrição
      this.toastService.showSuccessToast(this.i18n.parabens, this.i18n.sucessoInscricao)
      // Efetua login com os dados do formulário
      this.usuarioService.executeLogin(this.form.get('email')?.value ?? '', this.form.get('senha')?.value ?? '')
    } else {
      this.loading = false
      this.toastService.showErrorToast(this.i18n.atencao, inscricaoResponse.message)
    }
  }

  algumCampoAtendimentoEspecialHabilitado(): boolean {
    const lactante = this.form.get('lactante')?.value === 'true';
    const canhoto = this.form.get('canhoto')?.value === 'true';
    const possuiOutraNecessidade = this.form.get('possuiOutraNecessidade')?.value === 'true';
  
    return lactante || canhoto || possuiOutraNecessidade;
  }

  validaCPF(): void {
    this.validacaoService.validaCPF(this.form)
  }

  validaEstrangeiro(): void {
    this.validacaoService.validaEstrangeiro(this.form)
  }

  validaAtendimentoEspecial(): void {
    const algumHabilitado = this.algumCampoAtendimentoEspecialHabilitado()
    this.mostrarRecursosEspeciaisProva = algumHabilitado
    this.validacaoService.validaAtendimentoEspecial(this.form, algumHabilitado)
  }

  validaDeficiencia(): void {
    this.validacaoService.validaDeficiencia(this.form)
  }

  validaAutodeclaraRaca(): void {
    this.validacaoService.validaAutodeclaraRaca(this.form)
  }

  validaReducao(): void {
    this.validacaoService.validaReducao(this.form)
  }

  validaDataNascimento(): void {
    this.validacaoService.validaDataNascimento(this.form)
  }

  validaDataExpedicao(): void {
    this.validacaoService.validaDataExpedicao(this.form)
  }

  selecionaEstado(): void {
    const estado = this.form.get('estado')?.value
    this.cidades = this.cidadeService.get(estado ?? '')
  }
  
  onFileSelected(event: any, name: string): void {
    let file = event.target.files[0]
    let erro: boolean = false
    if (file) {
      erro = this.validateFile(name, file)
    }
    if (erro) {
      this.handleFileError(name)
    } else {
      this.handleFileSuccess(name, file)
    }
  }

  validateFile(fileName: string, file: File): boolean {
    const fileType = file.type
    let erro: boolean = false  
    if (fileName === 'documentoIdentificacao' || fileName === 'requerimentoNomeSocial') {
      if (!fileType || (fileType !== 'application/pdf' && fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg')) {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoTipo)
        erro = true
      } 

      if (file.size > 10485760) {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoExcedeu10mb)
        erro = true
      }
    } else {
        if (file.type !== 'application/pdf') {
          this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoPdf)
          erro = true
        }
        if (file.size > 104857600) {
          this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoExcedeu100mb)
          erro = true
        }
    }
    return erro
  }

  handleFileError(fileName: string): void {
    if (fileName === 'documentoIdentificacao') {
      this.inputClassDocumentoIdentificacao = 'form-control is-invalid'
      this.form.controls['documentoIdentificacao'].reset()
    }
    if (fileName === 'reducaoComprovanteEstudante') {
      this.inputClassComprovanteEstudante = 'form-control is-invalid'
      this.form.controls['reducaoComprovanteEstudante'].reset()
    }
    if (fileName === 'reducaoComprovanteRenda') {
      this.inputClassComprovanteRenda = 'form-control is-invalid'
      this.form.controls['reducaoComprovanteRenda'].reset()
    }
    if (fileName === 'deficienciaLaudoMedico') {
      this.inputClassDeficienciaLaudoMedico = 'form-control is-invalid'
      this.form.controls['deficienciaLaudoMedico'].reset()
    }
    if (fileName === 'atendimentoEspecialLaudoMedico') {
      this.inputClassAtendimentoEspecialLaudoMedico = 'form-control is-invalid'
      this.form.controls['atendimentoEspecialLaudoMedico'].reset()
    }
    if (fileName === 'requerimentoNomeSocial') {
      this.inputClassAtendimentoEspecialLaudoMedico = 'form-control is-invalid'
      this.form.controls['requerimentoNomeSocial'].reset()
    }
  }

  handleFileSuccess(fileName: string, file: File): void {
    if (fileName === 'documentoIdentificacao') {
      this.documentoIdentificacao = file
      this.inputClassDocumentoIdentificacao = 'form-control is-valid'
      return
    }
    if (fileName === 'reducaoComprovanteEstudante') {
      this.inputClassComprovanteEstudante = 'form-control is-valid'
      this.comprovanteEstudante = file
      return
    }
    if (fileName === 'reducaoComprovanteRenda') {
      this.comprovanteRenda = file
      this.inputClassComprovanteRenda = 'form-control is-valid'
      return
    }
    if (fileName === 'deficienciaLaudoMedico') {
      this.deficienciaLaudoMedico = file
      this.inputClassDeficienciaLaudoMedico = 'form-control is-valid'
      return
    }
    if (fileName === 'atendimentoEspecialLaudoMedico') {
      this.atendimentoEspecialLaudoMedico = file
      this.inputClassAtendimentoEspecialLaudoMedico = 'form-control is-valid'
    }
    if (fileName === 'requerimentoNomeSocial') {
      this.requerimentoNomeSocial = file
      this.inputClassAtendimentoEspecialLaudoMedico = 'form-control is-valid'
    }
  }

  validaCEP(enderecoNacional: boolean): void {
    this.validacaoService.validaCEP(this.form, enderecoNacional)
  }

  alteraNomeSocial(): void {
    this.nomeSocial = !this.nomeSocial
    this.validaNomeSocial()
  }

  validaNomeSocial(): void {
    this.validacaoService.validaNomeSocial(this.form, this.nomeSocial)
    localStorage.setItem('chkNomeSocial', this.nomeSocial.toString())
  }

  alteraMaeDesconhecida(): void {
    this.nomeMae = !this.nomeMae
    this.validaMaeDesconhecida()
  }

  validaMaeDesconhecida(): void {
    this.validacaoService.validaMaeDesconhecida(this.form, this.nomeMae)
    localStorage.setItem('chkNomeMae', this.nomeMae.toString())
  }

  alteraPaiDesconhecido(): void {
    this.nomePai = !this.nomePai
    this.validaPaiDesconhecido()
  }

  validaPaiDesconhecido(): void {
    this.validacaoService.validaPaiDesconhecido(this.form, this.nomePai)
    localStorage.setItem('chkNomePai', this.nomePai.toString())
  }

  lowerCase(name: string): void {
    this.form.get(name)?.setValue(this.form.get(name)?.getRawValue().toString().toLowerCase())
  }

  save(name: string) {
    localStorage.setItem(name, this.form.get(name)?.getRawValue())
  }

  private formDataFromLocalStorage(): void {
    for (const name in this.form.controls) {
      let value = localStorage.getItem(name)
      if (value) {
        if (name == 'deficiencia') {
          this.form.get(name)?.setValue(value?.split(','))
        } else {
          this.form.get(name)?.setValue(value)
        }
      }
    }
    this.nomeSocial =
      localStorage.getItem('chkNomeSocial') != null ? this.nomeSocial : localStorage.getItem('chkNomeSocial') == 'true'
    this.nomeMae =
      localStorage.getItem('chkNomeMae') == null ? this.nomeMae : localStorage.getItem('chkNomeMae') == 'true'
    this.nomePai =
      localStorage.getItem('chkNomePai') == null ? this.nomePai : localStorage.getItem('chkNomePai') == 'true'
  }

  alteraTipoEndereco(): void {
    this.enderecoNacional = this.form.controls['enderecoNacional'].value === 'true' ? true : false
  }

  isFieldRequired(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return control?.hasValidator(Validators.required) ?? false;
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'shared.parabens',
      'shared.erro_formulario',
      'arquivo_shared.arquivo_pdf',
      'arquivo_shared.arquivo_excedeu',
      'arquivo_shared.arquivo_tipo',
      'arquivo_shared.arquivo_excedeu_10mb',
      'ficha_inscricao.concurso_fora_periodo',
      'ficha_inscricao.senhas_nao_batem',
      'ficha_inscricao.cpf_invalido',
      'ficha_inscricao.confira_dados',
      'ficha_inscricao.selecao_temas',
      'ficha_inscricao.erro_reducao_taxa',
      'ficha_inscricao.erro_laudo',
      'ficha_inscricao_shared.sucesso_inscricao',
      'ficha_inscricao.erro_identificacao',
      'ficha_inscricacao.erro_requerimento_nome_social'
    ])
    .subscribe(traducoes => {
      this.i18n.atencao = traducoes['shared.atencao'];
      this.i18n.parabens = traducoes['shared.parabens'];
      this.i18n.erroFormulario = traducoes['shared.erro_formulario'];
      this.i18n.arquivoPdf = traducoes['arquivo_shared.arquivo_pdf'];
      this.i18n.arquivoExcedeu100mb = traducoes['arquivo_shared.arquivo_excedeu'];
      this.i18n.arquivoTipo = traducoes['arquivo_shared.arquivo_tipo'];
      this.i18n.arquivoExcedeu10mb = traducoes['arquivo_shared.arquivo_excedeu_10mb'];
      this.i18n.concursoForaPeriodo = traducoes['ficha_inscricao.concurso_fora_periodo'];
      this.i18n.senhasNaoBatem = traducoes['ficha_inscricao.senhas_nao_batem'];
      this.i18n.cpfInvalido = traducoes['ficha_inscricao.cpf_invalido'];
      this.i18n.confiraDados = traducoes['ficha_inscricao.confira_dados'];
      this.i18n.selecaoTemas = traducoes['ficha_inscricao.selecao_temas'];
      this.i18n.erroReducaoTaxas = traducoes['ficha_inscricao.erro_reducao_taxa'];
      this.i18n.erroLaudoDeficiencia = traducoes['ficha_inscricao.erro_laudo_deficiencia'];
      this.i18n.erroLaudoAtendimentoEspecial = traducoes['ficha_inscricao.erro_laudo_atendimento_especial']
      this.i18n.sucessoInscricao = traducoes['ficha_inscricao_shared.sucesso_inscricao'];
      this.i18n.erroIdentificacao = traducoes['ficha_inscricacao.erro_identificacao']
      this.i18n.erroRequerimentoNomeSocial = traducoes['ficha_inscricacao.erro_requerimento_nome_social']
    });
  }
}
