/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { ConcursoAndamento } from 'src/app/models/concurso-andamento'
import { ConcursoService } from 'src/app/services/concurso.service'
import { DownloadService } from 'src/app/services/download.service'
import { Meta } from '@angular/platform-browser'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.css'],
})
export class ConcursoComponent implements OnInit {
  id?: number
  concursoAndamento?: ConcursoAndamento
  loading: boolean = true
  i18n = {
    atencao: '',
    urlInvalida: ''
  }

  constructor(
    private readonly concursoService: ConcursoService,
    private readonly downloadService: DownloadService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly meta: Meta,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    this.route.params.subscribe(async params => {
      this.id = Number(params['id'])
      let caracteresEspeciais = params['id'].replace(/[\d\s]/g, '')

      if (this.id && typeof this.id === 'number' && Number.isInteger(this.id) && caracteresEspeciais.length == 0) {
        this.concursoService.get(this.id).subscribe(obj => {
          if (obj) {
            this.meta.updateTag({ name: 'description', content: obj.titulo })
            this.concursoAndamento = obj
            this.loading = false
          } else {
            setTimeout(() => {
              this.router.navigate(['/concurso/inscricao-aberta'])
            }, 2000)
          }
        })
      } else {
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.urlInvalida)
        setTimeout(() => {
          this.voltar()
        }, 2000)
      }
    })
  }

  voltar(): void {
    this.location.back()
  }

  edital(): void {
    this.downloadService.edital(this.concursoAndamento?.id ?? 0)
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'concurso.url_invalida'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.urlInvalida = traducoes['concurso.url_invalida']
    })
  }
}
