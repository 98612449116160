<fieldset class="border rounded-3 p-2">
  <legend class="w-auto">{{'shared.inscricao' | translate}}</legend>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'shared.unidade' | translate}}</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.campus }}" type="text" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'shared.concurso' | translate}}</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.tituloConcurso }}" type="text" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'candidato_fieldset.periodo_inscricao' | translate}}</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.inicioConcurso | date : 'dd/MM/yyyy' }} {{'shared.ate' | translate}} {{
            inscricaoFicha?.fimConcurso | date : 'dd/MM/yyyy'
          }} {{'shared.as' | translate}} {{ inscricaoFicha?.horaFimConcurso?.substring(0, 5) }} h"
          type="text" />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inscricaoFicha?.inicioFase2 && inscricaoFicha?.fimFase2">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'candidato_fieldset.periodo_segunda_fase' | translate}}</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.inicioFase2 | date : 'dd/MM/yyyy' }} {{'shared.ate' | translate}} {{
            inscricaoFicha?.fimFase2 | date : 'dd/MM/yyyy'
          }} {{'shared.as' | translate}} 23:59 h"
          type="text" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'candidato_fieldset.protocolo' | translate}}</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.protocolo }}" type="text" />
      </div>
    </div>
    <div class="col-sm-3" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'candidato_fieldset.data' | translate}}</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.data | date : 'dd/MM/yyyy HH:mm:ss' }}"
          type="text" />
      </div>
    </div>
    <div class="col-sm-3" style="margin-top: 0.5%" *ngIf="inscricaoFicha?.valor != 0">
      <div class="input-group">
        <span class="input-group-text">{{'candidato_fieldset.pagamento' | translate}}</span>
        <input
          readonly="readonly"
          class="form-control"
          value="{{ inscricaoFicha?.pago ? ('candidato_inscricao_shared.confirmado' | translate) : ('candidato_inscricao_shared.pendente' | translate) }}"
          type="text" />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inscricaoFicha?.status !== 'Não confirmada'">
    <div class="col-sm-12" style="margin-top: 0.5%">
      <div class="input-group">
        <span class="input-group-text">{{'candidato_fieldset.situacao_da_inscricao' | translate}}</span>
        <input readonly="readonly" class="form-control" value="{{ inscricaoFicha?.status }}" type="text" />
      </div>
    </div>
  </div>
</fieldset>
