/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { InscricaoComplemento } from 'src/app/models/inscricao-complemento'
import { InscricaoComplementoService } from 'src/app/services/inscricao-complemento.service'
import { ModalService } from '../file-preview-modal/services/modal.service'
import { ToastService } from '../../toast/services/toast.service'
import { Subscription } from 'rxjs/internal/Subscription'
import { DownloadService } from 'src/app/services/download.service'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-formulario-file',
  templateUrl: './formulario-file.component.html',
  styleUrls: ['./formulario-file.component.css'],
})
export class FormularioFileComponent implements OnInit {
  @Output() stateChanged = new EventEmitter()
  @Input() inscricaoComplemento!: InscricaoComplemento
  @Input() periodoPermitido?: boolean
  file?: File
  inputClass: string = 'form-control'
  loading: boolean = false
  private eventsSubscription?: Subscription
  i18n = {
    atencao: '',
    arquivoPdf: '',
    arquivoCandidato: '',
    arquivoExcedeu: '',
    arquivoExcedeuCandidato: '',
    sucessoEnvio: '',
    erroEnvio: '',
    parabens: ''
  }

  constructor(
    private inscricaoComplementoService: InscricaoComplementoService,
    private downloadService: DownloadService,
    private toastService: ToastService,
    private modalService: ModalService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();
    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0]
    if (this.file) {
      const allowedExtensions = environment.allowedFileExtensions.split(',');
      const fileExtension = this.file.name.split('.').pop()?.toLowerCase();
      if (!fileExtension || !environment.allowedFileExtensions.includes(fileExtension)) {
        this.inputClass = 'form-control is-invalid';
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoCandidato + " É permitido as extensões: " + environment.allowedFileExtensions);
        return;
      }

      const allowedFileSizeBytes = Number(environment.allowedFileSizeMb) * 1024 * 1024;

      if (this.file.size > allowedFileSizeBytes) {
        this.inputClass = 'form-control is-invalid'
        this.toastService.showErrorToast(this.i18n.atencao, this.i18n.arquivoExcedeuCandidato 
          + " É permitido arquivos até: " + environment.allowedFileSizeMb + "MB")
        return
      }
      this.inputClass = 'form-control'
      this.modalService.showPreview(this.file, this.inscricaoComplemento.grupo, this.inscricaoComplemento.explicacao)
      this.eventsSubscription = this.modalService.modalEventsEnviar.subscribe( result => {
        if(result) this.enviar()
        else (event.target as HTMLInputElement).value = ''
        this.eventsSubscription?.unsubscribe()
      })
    }
  }

  enviar(): void {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
  
      if (this.inscricaoComplemento) {
        this.loading = true;
  
        this.inscricaoComplementoService
          .upload(this.inscricaoComplemento.idInscricao, this.inscricaoComplemento.id, formData)
          .subscribe({
            next: (inscricaoComplemento) => {
              if (inscricaoComplemento) {
                this.inscricaoComplemento = inscricaoComplemento;
                this.toastService.showSuccessToast(this.i18n.parabens, this.i18n.sucessoEnvio);
              } else {
                this.toastService.showErrorToast(this.i18n.atencao, this.i18n.erroEnvio);
              }
              this.loading = false;
              this.stateChanged.emit();
            },
            error: (err) => {              
              const errorMessage = err.error?.message || this.i18n.erroEnvio;
              
              this.toastService.showErrorToast(this.i18n.atencao, errorMessage);
              this.loading = false;
              this.stateChanged.emit();
            },
          });
      }
    }
  }

  open(): void {
    // this.downloadService.complemento(this.inscricaoComplemento.id)
    this.downloadService.complementoAcessoDireto(this.inscricaoComplemento.idConcurso, this.inscricaoComplemento.valor)
  }

  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'arquivo_shared.arquivo_excedeu',
      'arquivo_shared.arquivo_pdf',
      'arquivo_shared.arquivo_candidato',
      'arquivo_shared.sucesso_envio',
      'arquivo_shared.erro_envio',
      'shared.parabens'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.arquivoExcedeu = traducoes['arquivo_shared.arquivo_excedeu']
      this.i18n.arquivoExcedeuCandidato = traducoes['arquivo_shared.arquivo_excedeu_candidato']
      this.i18n.arquivoPdf = traducoes['arquivo_shared.arquivo_pdf']
      this.i18n.arquivoCandidato = traducoes['arquivo_shared.arquivo_candidato']
      this.i18n.sucessoEnvio = traducoes['arquivo_shared.sucesso_envio']
      this.i18n.erroEnvio = traducoes['arquivo_shared.erro_envio']
      this.i18n.parabens = traducoes['shared.parabens']
    })
  }
}
