/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { ActivatedRoute, Router } from '@angular/router'
import { InscricaoFicha } from 'src/app/models/inscricao-ficha'
import { Subject } from 'rxjs'
import { FormularioSituacaoComponent } from '../../formulario/formulario-situacao/formulario-situacao.component'
import { InscricaoComplementoService } from '../../../services/inscricao-complemento.service'
import { ToastService } from '../../toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-candidato-inscricao-complemento-fase2',
  templateUrl: './candidato-inscricao-complemento-fase2.component.html',
  styleUrls: ['./candidato-inscricao-complemento-fase2.component.css'],
})
export class CandidatoInscricaoComplementoFase2Component implements OnInit {
  eventsSubject: Subject<void> = new Subject<void>()

  @ViewChild('situacaoAnexos') situacaoAnexo?: FormularioSituacaoComponent

  id?: number
  inscricaoFicha?: InscricaoFicha
  permiteEdicao: boolean = false
  temComplemento: boolean = false
  quantidadeComplementos: number = 0
  contaAnexos: number = 0
  desabilitaBotaoEnvio = true
  idConcurso: number | undefined
  email: string | undefined
  prazoInscricoesAcabou: boolean = false
  etapa: string = 'FASE_2'
  inscricaoCompleta: boolean = false
  ausentes: boolean = false
  enableTelaContato: string = environment.enableTelaContato
  i18n = {
    atencao: '',
    sucessoEmail: '',
    erroEmail: ''
  }

  constructor(
    private inscricaoService: InscricaoService,
    private route: ActivatedRoute,
    private inscricaoComplementoService: InscricaoComplementoService,
    private toastService: ToastService,
    private router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.carregarI18n();

    this.translateService.onLangChange.subscribe(() => {
      this.carregarI18n();
    });

    this.route.params.subscribe(params => {
      this.id = params['id']

      if (this.id) {
        this.inscricaoService.get(this.id).subscribe(obj => {
          this.inscricaoFicha = obj
          this.temComplemento = obj.inscricaoComplementoFase2.length > 0

          this.inscricaoComplementoService.pendencia(this.id!).subscribe(objAusentes => {
            this.ausentes =
              this.situacaoAnexo?.inscricaoComplementoSituacao?.quantidadeTotaldeArquivosAusentesSegundaFase! > 0
            this.inscricaoCompleta =
              this.situacaoAnexo?.inscricaoComplementoSituacao?.totalObrigatorioPendenteSegundaFase! > 0
          })

          this.quantidadeComplementos = obj.inscricaoComplementoFase2.length
          this.idConcurso = this.inscricaoFicha.idConcurso
          this.email = this.inscricaoFicha.email
        })
      }
    })
  }

  onStateChange(): void {
    this.eventsSubject.next()
  }

  gravar() {
    let jsonData = {
      idConcurso: this.idConcurso,
      email: this.email,
      etapa: this.etapa,
    }

    this.inscricaoComplementoService.verificaPeriodoEnvio(this.id!, jsonData).subscribe(resultado => {
      if (resultado) {
        this.inscricaoComplementoService
          .notificaCandidatoSobreAnexos(this.id!, jsonData)
          .subscribe(inscricaoComplemento => {
            this.toastService.showSuccessToast(this.i18n.atencao, this.i18n.sucessoEmail)
          })
      } else {
        this.toastService.showErrorToast(
          this.i18n.atencao,
          this.i18n.erroEmail
        )
      }
    })

    this.voltar()
  }

  voltar(): void {
    this.router.navigate(['/candidato/inscricoes'], { relativeTo: this.route })
  }
  
  private carregarI18n(): void {
    this.translateService.get([
      'shared.atencao',
      'candidato_inscricao_shared.sucesso_email',
      'candidato_inscricao_shared.erro_email'
    ])
    .subscribe(traducoes =>{
      this.i18n.atencao = traducoes['shared.atencao']
      this.i18n.sucessoEmail = traducoes['candidato_inscricao_shared.sucesso_email']
      this.i18n.erroEmail = traducoes['candidato_inscricao_shared.erro_email']
    })
  }
}
