<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="{{'shared.area_do_candidato' | translate}}" class="link">{{'shared.area_do_candidato' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'candidato_inscricao_shared.documentacao_complementar_segunda_fase' | translate}}</li>
      </ol>
    </nav>

    <app-formulario-situacao
      [idInscricao]="id"
      [events]="eventsSubject.asObservable()"
      #situacaoAnexos></app-formulario-situacao>

    <form>
      <app-candidato-fieldset-inscricao [inscricaoFicha]="inscricaoFicha"></app-candidato-fieldset-inscricao>

      <fieldset class="border rounded-3 p-2 no-print" *ngIf="temComplemento">
        <legend class="w-auto">{{'candidato_inscricao_shared.documentacao_complementar_segunda_fase' | translate}}</legend>
        <div
          *ngFor="let inscricaoComplemento of inscricaoFicha?.inscricaoComplementoFase2"
          [ngSwitch]="inscricaoComplemento.tipoComplemento">
          <app-formulario-file
            *ngSwitchCase="'FILE'"
            [inscricaoComplemento]="inscricaoComplemento"
            [periodoPermitido]="inscricaoFicha?.periodoSegundaFase"
            (stateChanged)="onStateChange()">
          </app-formulario-file>
          <app-formulario-checkbox
            *ngSwitchCase="'CHECKBOX'"
            [inscricaoComplemento]="inscricaoComplemento"
            [periodoPermitido]="inscricaoFicha?.periodoSegundaFase"
            (stateChanged)="onStateChange()">
          </app-formulario-checkbox>
        </div>
      </fieldset>
    </form>
    <div class="no-print">
      <div class="row mt-3" *ngIf="enableTelaContato === 'true'">
        <div class="col-12">
          <div class="alert alert-warning">
            {{'candidato_inscricao_shared.caso_duvida_alteracao' | translate}}
            <a routerLink="/candidato/contato/{{ inscricaoFicha?.idConcurso }}">{{'candidato_inscricao_shared.clique_aqui' | translate}}</a>.
          </div>
        </div>
      </div>
      <div class="row mt-3" style="margin-top: 10px">
        <div class="col-6">
          <a routerLink="/candidato/inscricoes" class="btn btn-secondary btn-sm"
            ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>{{'shared.voltar' | translate}}</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse" *ngIf="temComplemento && inscricaoFicha?.periodoSegundaFase">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#dialogConcluirModal">
            {{'candidato_inscricao_shared.confirmar_envio_doc' | translate}}
          </button>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="dialogConcluirModal"
          tabindex="-1"
          aria-labelledby="dialogConcluirModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="dialogConcluirModalLabel">{{'candidato_inscricao_shared.envio_arquivos' | translate}}</h1>
              </div>
              <div class="modal-body" [hidden]="!inscricaoCompleta">
                {{'candidato_inscricao_shared.falta_insercao' | translate}}
                {{ this.situacaoAnexo?.inscricaoComplementoSituacao?.quantidadeTotaldeArquivosAusentesSegundaFase }}
                {{'candidato_inscricao_shared.documentos_gravar' | translate}}
              </div>
              <div class="modal-body" [hidden]="inscricaoCompleta">
                {{'candidato_inscricao_shared.documentos_enviados' | translate}}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn btn-warning btn-sm" data-bs-dismiss="modal">
                  {{'candidato_inscricao_shared.retornar_finalizar' | translate}}
                </button>
                <button type="button" (click)="gravar()" class="btn btn-primary" data-bs-dismiss="modal">{{'shared.sim' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
