import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../services/modal.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FilePreview } from '../models/file-preview'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  filePreview?: FilePreview
  exibir: boolean = false
  @Input() trustedUrl!: SafeUrl
  isPreviewable: boolean = true

  constructor(private sanitizer: DomSanitizer, private modalService: ModalService) {}

  ngOnInit(): void {
    this.subscribeToModal()
  }

  subscribeToModal() {
    this.modalService.modalEventsArquivo.subscribe(filePreview => {
      this.filePreview = filePreview
            
      const fileExtension = filePreview.file.name.split('.').pop()?.toLowerCase()      
      this.isPreviewable = fileExtension !== 'zip'

      if (this.isPreviewable) {
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(filePreview.file))
      } else {
        this.trustedUrl = ''
      }

      this.toggle()
    })
  }

  toggle(): void {
    this.exibir = !this.exibir
  }

  cancelar(): void {
    this.toggle()
    this.modalService.enviar(false)
  }

  enviar(): void {
    this.toggle()
    this.modalService.enviar(true)
  }
}
